import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function syncData(params) {
  return request({
    url: baseURL + '/admin/sync',
    method: 'get',
    params: params
  })
}

export function fetchSyncDataLatest(params) {
  return request({
    url: baseURL + '/admin/sync/latest',
    method: 'get',
    params: params
  })
}

export function fetchDashboardData(params) {
  return request({
    url: baseURL + '/admin/dashboard',
    method: 'get',
    params: params
  })
}

export function fetchAdmin(params) {
  return request({
    url: baseURL + '/account/list',
    method: 'get',
    params: params
  })
}

export function fetchDetailAdmin(params) {
  return request({
    url: baseURL + '/account/detail',
    method: 'get',
    params: params
  })
}

export function saveAdmin(data) {
  return request({
    url: baseURL + '/account/save',
    method: 'post',
    data
  })
}

export function deleteAdmin(data) {
  return request({
    url: baseURL + '/account/delete',
    method: 'delete',
    data
  })
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {fetchDetailAdmin, saveAdmin} from "@/api/admin";
import {fetchMenuPrivList, fetchBranch, fetchRole} from "@/api/master";
import {Message} from "element-ui";

export default {
	name: 'User',
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.usersOpened = from.fullPath.includes('users')
		})
	},
	created() {
		document.title = "User : ONE Tracemoney"
	},
	data() {
		return {
			mode: "edit",
			usersOpened: null,
			userDetail: null,
			activePage: 1,
			grantViewed: false,
			grantEdited: false,
			grantReported: false,
			password: "",
			password2: "",
			formData: {
				name: "",
				surname: "",
				username: "",
				nickname: "",
				email: "",
				branchId: "",
				phone: "",
				is_active: 1,
				privileges: []
			},
			branches: [
				"กรุณาเลือกสาขา"
			],
			roles: [
				{value: null, label: "กรุณาเลือกตำแหน่ง"}
			],
			menus: [],
			tableFields: [
				{key: 'menu_id', label: '#', _style: 'min-width:50px', _classes: 'col-xs-1 font-weight-bold'},
				{key: 'menu', label: 'ชื่อเมนู', _style: 'min-width:200px'},
				{key: 'description', label: 'รายละเอียด', _style: 'min-width:200px'},
				{
					key: 'show_view',
					label: 'ดู',
					_style: 'min-width:100px',
					_classes: 'text-center',
					sorter: false,
					filter: false
				},
				{
					key: 'show_edit',
					label: 'เพิ่ม/แก้ไข',
					_style: 'min-width:100px',
					_classes: 'text-center',
					sorter: false,
					filter: false
				},
				{
					key: 'show_report',
					label: 'รายงาน',
					_style: 'min-width:100px',
					_classes: 'text-center',
					sorter: false,
					filter: false
				}
			]
		}
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			await this.getUser()
			await new Promise((resolve, reject) => {
				fetchBranch().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.branches = this.branches.concat(data)
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
				fetchRole().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.roles = this.roles.concat(data)
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
				fetchMenuPrivList().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.menus = data
						for (const menu of data) {
							const getMenu = this.formData.privileges.find(element => element.menu_id === menu.value);
							if (!getMenu) {
								this.formData.privileges.push({
									menu_id: menu.value,
									read: false,
									write: false,
									report: false
								})
							}
						}
						this.formData.privileges = this.formData.privileges.sort((a,b) => a.menu_id - b.menu_id);
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		async getUser() {
			this.mode = this.$route.params.id
			if (this.mode && this.mode !== "add") {
				const params = {
					"accountId": this.mode
				}
				await fetchDetailAdmin(params).then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.formData = data
						if (data.privileges) {
							for (const item in data.privileges) {
								if (data.privileges[item] !== null)
									this.formData.privileges[item] = data.privileges[item]
							}
						}
					}
				}).catch(error => {
					Message({
						message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
						type: 'error',
						duration: 5 * 1000,
					})
					this.errorMsg = error
					console.log(error)
				})
			}
		},
		grantAll() {
			this.grantViewAll()
			this.grantEditAll()
			this.grantReportAll()
		},
		grantViewAll() {
			this.grantViewed = !this.grantViewed
			const newArr = this.formData.privileges.map(object => {
				// 👇️ change value of name property
				return {...object, read: this.grantViewed};
			});
			this.formData.privileges = newArr
		},
		grantEditAll() {
			this.grantEdited = !this.grantEdited
			const newArr = this.formData.privileges.map(object => {
				// 👇️ change value of name property
				return {...object, write: this.grantEdited};
			});
			this.formData.privileges = newArr
		},
		grantReportAll() {
			this.grantReported = !this.grantReported
			const newArr = this.formData.privileges.map(object => {
				// 👇️ change value of name property
				return {...object, report: this.grantReported};
			});
			this.formData.privileges = newArr
		},
		check(e) {
			this.formData.privileges.find(element => element.menu_id === e).read = true
		},
		validator(val) {
			if (val) {
				return val.length >= 6 && val === this.password2
			}
			return false
		},
		validator2(val) {
			if (val) {
				return val.length >= 6 && val === this.password
			}
			return false
		},
		chkActive(e) {
			this.formData.is_active = e ? 1 : 0
		},
		saveUser() {
			this.$store.state.loading = true
			let msg = '';
			let branchId = Number(this.formData.branchId)
			if (isNaN(branchId)) {
				msg = 'กรุณาระบุสาขา'
			} else if (this.password || this.password2) {
				if (this.password === this.password2) {
					console.log("change password!!")
					this.formData.password = this.password
				} else {
					msg = 'หากต้องการเปลี่ยนรหัสผ่าน กรุณากรอกรหัสผ่าน และรหัสผ่าน (อีกครั้ง) ให้ถูกต้อง'
				}
			}
			if (msg !== '') {
				Message({
					message: msg,
					type: 'warning',
					duration: 5 * 1000
				})
				this.$store.state.loading = false
				return false
			}
			let priv = this.formData.privileges
			priv = priv.filter(item => item.read === true || item.write === true || item.report === true);
			this.formData.granted = priv

			saveAdmin(this.formData).then(response => {
				const check = response.header
				const data = response.body
				if (check.error === 'N') {
					this.goBack()
					Message({
						message: 'บันทึกข้อมูลสำเร็จ',
						type: 'success',
						duration: 5 * 1000
					})
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log(error)
				this.$store.state.loading = false
			})
		},
		pageChange(val) {
			this.$router.push({query: {page: val}})
		},
		goBack() {
			this.$store.state.loading = true
			this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/admin/users'})
		}
	}
}
